import React from "react";
import { useSelector } from "react-redux";
import Title from "../title/Title";
import PortfolioDetails from "../portfolioDetails/PortfolioDetails";

const Portfolio = () => {
  const selectMode = useSelector(({ IsDarkMode }) => IsDarkMode);

  return (
    <>
      <section className={`portfolio ${selectMode ? "" : "light__mode"}`}>
        {/* portfolio section start */}
        <div className="container-fluid">
          <div className="row">
            {/* row start */}
            <div className="col-md-half4">
              {/* col-md-half4 start */}
              <div className="portfolio__title">
                <Title title="portfolio" subtitle="Here it gets interesting." />
              </div>
            </div>
            {/* col-md-half4 End */}
            <div className="col-md-half9">
              {/* col-md-half9 start */}
              <PortfolioDetails />
            </div>
            {/* col-md-half9 End */}
          </div>
          {/* row End */}
        </div>
      </section>
      {/* Skill section End  */}
    </>
  );
};

export default Portfolio;
