import React from "react";
import { useSelector } from "react-redux";
import ResumeCv from "../../constants/ResumeCv.json";
import Title from "../title/Title";

const Experience = () => {
  const selectMode = useSelector(({ IsDarkMode }) => IsDarkMode);
  const experience = ResumeCv.experience; // get Experience data from Json
  return (
    <section className={`experience ${selectMode ? "" : "light__mode"}`}>
      {/* Experience section start */}
      <div className="container-fluid">
        <div className="row">
          {/* row start */}
          <div className="col-md-half4">
            {/* col-md-half4 start */}
            <div className="experience__title">
              <Title title="experience" subtitle="Yes. I've been around." />
            </div>
          </div>
          {/* col-md-half4 End */}
          <div className="col-md-half9">
            {/* col-md-half9 start */}
            {
              // loop to get Experience fields
              experience.map((item, index) => {
                return (
                  <div className="row experience__info" key={index}>
                    <div className="col-lg-6">
                      <div className="round__dot"></div>
                      <span className="workingYear">
                        {item.workingtimeframe}
                      </span>
                      <h4 className="company">{item.company}</h4>
                      <h5 className="position">{item.position}</h5>
                      <h6 className="location">{item.location}</h6>
                    </div>
                    <div className="col-lg-6 description">
                      <p>{item.description}</p>
                    </div>
                  </div>
                );
              })
            }
          </div>
          {/* col-md-half9 End */}
        </div>
        {/* row End */}
      </div>
    </section> // Experience section End
  );
};

export default Experience;
