import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { BiChevronLeft } from "react-icons/bi";
import { BiChevronRight } from "react-icons/bi";
import { darkMode, lightMode } from "../../redux/actions/Actions";

const SwitchButton = () => {
  const [hideSwitch, sethideSwitch] = useState(false); // To hide and show Switch-bar
  const Isdark = useSelector(({ IsDarkMode }) => IsDarkMode); // get state of redux
  const dispatch = useDispatch();
  return (
    <div className={`switch  ${hideSwitch && "show__switch"} `}>
      <h5 className="switch__title">change version</h5>
      <div className="switch__buttonContainer">
        <button
          className={`switch__button ${!Isdark && "active"} `}
          onClick={() => dispatch(lightMode())}
        >
          light
        </button>
        <button
          className={`switch__button ${Isdark && "active"} `}
          onClick={() => dispatch(darkMode())}
        >
          dark
        </button>
      </div>
      <button
        className="switch__toggle--button"
        onClick={() => sethideSwitch(!hideSwitch)}
      >
        {hideSwitch ? (
          <BiChevronRight className="switch__arrow" />
        ) : (
          <BiChevronLeft className="switch__arrow" />
        )}
      </button>
    </div>
  );
};

export default SwitchButton;
