import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { useSelector } from "react-redux";
import Title from "../title/Title";

// get markDown file data locate in constants folder
const query = graphql`
  {
    markdownRemark {
      html
    }
  }
`;

const Intro = () => {
  const data = useStaticQuery(query);
  const html = data.markdownRemark.html; // get Intro paragraph from .md file
  const selectMode = useSelector(({ IsDarkMode }) => IsDarkMode);
  return (
    <section className={`intro ${selectMode ? "" : "light__mode"} `}>
      {/* Intro section start */}
      <div className="container-fluid">
        <div className="row">
          {/* row start */}
          <div className="col-md-half4">
            {/* col-md-half4 start */}
            <div className="intro__title">
              <Title title="intro" subtitle="What I am all about." />
            </div>
          </div>
          {/* col-md-half4 End */}
          <div className="col-md-half9">
            {/* col-md-half9 start */}
            <div
              className="intro__info"
              dangerouslySetInnerHTML={{ __html: html }} // use for display .md data
            ></div>
          </div>
          {/* col-md-half9 End */}
        </div>
        {/* row End */}
      </div>
    </section>
  );
};

export default Intro;
