import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { BiRightArrowAlt } from "react-icons/bi";
import { MdClose } from "react-icons/md";

// get portfolioDetails data from Json using graphql
const query = graphql`
  {
    allConstantsJson {
      nodes {
        portfolioDetails {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
            publicURL
            base
          }
          id
        }
      }
    }
  }
`;

const PortfolioDetails = () => {
  const data = useStaticQuery(query);
  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  const [ImgUrl, setImgUrl] = useState("");
  const [TextdataShow, setTextdataShow] = useState("");
  const [AllText, setAllText] = useState("");
  const dataUrl = []; // store Image Url
  const Textdata = []; // store text data

  //function to show a specific image in the lightbox, amd make lightbox visible
  const showImage = (images, text) => {
    setImageToShow(images);
    setLightBoxDisplay(true);
    setImgUrl(dataUrl);
    setTextdataShow(text);
    setAllText(Textdata);
  };
  //hide lightbox
  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };
  //show next image in lightbox
  const showNext = e => {
    e.stopPropagation();
    let nextImage;
    let nextText;
    let currentIndex = ImgUrl.indexOf(imageToShow);
    if (currentIndex >= ImgUrl.length - 1) {
      // setLightBoxDisplay(false);
      currentIndex = 0;
      nextImage = ImgUrl[currentIndex];
      nextText = AllText[currentIndex];
    } else {
      nextImage = ImgUrl[currentIndex + 1];
      nextText = AllText[currentIndex + 1];
    }
    setImageToShow(nextImage);
    setTextdataShow(nextText);
  };

  //show previous image in lightbox
  const showPrev = e => {
    let prevImage;
    let prevText;
    e.stopPropagation();
    let currentIndex = ImgUrl.indexOf(imageToShow);
    if (currentIndex <= 0) {
      currentIndex = ImgUrl.length - 1;
      prevImage = ImgUrl[currentIndex];
      prevText = AllText[currentIndex];
    } else {
      prevImage = ImgUrl[currentIndex - 1];
      prevText = AllText[currentIndex - 1];
    }
    setImageToShow(prevImage);
    setTextdataShow(prevText);
  };
  return (
    <>
      <div className="row">
        {data.allConstantsJson.nodes.map(items => {
          {
            return items.portfolioDetails.map((item, index) => {
              dataUrl.push(item.image.publicURL);
              Textdata.push(item.title);
              return (
                <>
                  <div className="col-md-6" key={index}>
                    <div className="portfolio__container">
                      <div className="image__container">
                        <GatsbyImage
                          image={item.image.childImageSharp.gatsbyImageData}
                          alt={item.title}
                          className="images"
                        />
                      </div>
                      <div
                        className="protfolio__info"
                        onClick={() =>
                          showImage(item.image.publicURL, item.title)
                        }
                        onKeyPress={() =>
                          showImage(item.image.publicURL, item.title)
                        }
                        role="button"
                        tabIndex="0"
                        aria-hidden="true"
                      >
                        <h4 className="title">{item.title}</h4>
                        <div className="underline"></div>
                        <p className="desc">{item.description}</p>
                      </div>
                    </div>
                  </div>

                  {lightboxDisplay ? (
                    <div id="lightbox">
                      <MdClose className="close" onClick={hideLightBox} />
                      <button onClick={showPrev}>
                        <BiLeftArrowAlt className="leftArrow" />
                      </button>
                      <img
                        id="lightbox-img"
                        src={imageToShow}
                        alt={item.title}
                      />
                      <p>{TextdataShow}</p>
                      <button onClick={showNext}>
                        <BiRightArrowAlt className="rightArrow" />
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            });
          }
        })}
      </div>
    </>
  );
};

export default PortfolioDetails;
