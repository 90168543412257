import React from "react";
import { FaLinkedinIn, FaGithub, FaSkype } from "react-icons/fa";
import { useSelector } from "react-redux";
import ResumeCv from "../../constants/ResumeCv.json";
import ContactInfo from "../ContactInfo/ContactInfo";
import Title from "../title/Title";
import Fade from "react-reveal/Fade";
const Contact = () => {
  const selectMode = useSelector(({ IsDarkMode }) => IsDarkMode);
  const contactInfo = ResumeCv.contactInfo; // get contact Info
  return (
    <section className={`contact ${selectMode ? "" : "light__mode"}`}>
      {/* contact section start */}
      <div className="container-fluid">
        <div className="row">
          {/* row start */}
          <div className="col-md-half4">
            {/* col-md-half4 start */}
            <div className="contact__title">
              <Title title="contact" subtitle="Call me, maybe." />
            </div>
          </div>
          {/* col-md-half4 End */}
          <div className="col-md-half9">
            {/* col-md-half9 start */}
            <div className="contact__Info">
              {/* contact__Info Start  */}
              <div className="row">
                <div className="col-lg-3 title__info">
                  <ContactInfo
                    title="Location"
                    value={contactInfo.location}
                    capitalize
                  />
                </div>
                <div className="col-lg-3 title__info">
                  <ContactInfo title="Phone" value={contactInfo.phone} />
                </div>
                <div className="col-lg-3 title__info">
                  <a href="/" style={{ textDecoration: "none" }}>
                    <ContactInfo title="Web" value={contactInfo.web} color />
                  </a>
                </div>
                <div className="col-lg-3 title__info">
                  <a href="/" style={{ textDecoration: "none" }}>
                    <ContactInfo
                      title="Email"
                      value={contactInfo.email}
                      color
                    />
                  </a>
                </div>
              </div>
              <div className="underline"></div>
            </div>
            {/* contact__Info End  */}
            <div className="info__container">
              {/* Info__container Start  */}
              <div className="profession__container">
                <Fade bottom duration={2000} delay={700}>
                  <h1 className="info__title">{ResumeCv.basic.name1}</h1>
                </Fade>
                <Fade bottom duration={2000} delay={900}>
                  <h2 className="info__subtitle">{ResumeCv.basic.name2}</h2>
                </Fade>
              </div>
              <div className="social__links">
                <Fade bottom duration={2000} delay={2200}>
                  <a
                    href={contactInfo.linkedIn}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaLinkedinIn className="icons" />
                  </a>
                </Fade>
                <Fade bottom duration={2000} delay={2300}>
                  <a href={contactInfo.github} target="_blank" rel="noreferrer">
                    <FaGithub className="icons" />
                  </a>
                </Fade>
                <Fade bottom duration={2000} delay={2400}>
                  <a href={contactInfo.skype} target="_blank" rel="noreferrer">
                    <FaSkype className="icons" />
                  </a>
                </Fade>
              </div>
            </div>
            {/* Info__container Start  */}
          </div>
          {/* col-md-half9 End */}
        </div>
        {/* row End */}
      </div>
    </section> // Experience section End
  );
};

export default Contact;
