import React from "react";
import Title from "../title/Title";
import ResumeCv from "../../constants/ResumeCv.json";
import { useSelector } from "react-redux";

const Expertises = () => {
  const expertise = ResumeCv.expertise; // get expertise data from Json
  const selectMode = useSelector(({ IsDarkMode }) => IsDarkMode);
  return (
    <section className={`expertise ${selectMode ? "" : "light__mode"}`}>
      {/* Expertise section start */}
      <div className="container-fluid">
        <div className="row">
          {/* row start */}
          <div className="col-md-half4">
            {/* col-md-half4 start */}
            <div className="expertise__title">
              <Title title="expertise" subtitle="Batman would be jealous." />
            </div>
          </div>
          {/* col-md-half4 End */}
          <div className="col-md-half9">
            {/* col-md-half9 start */}
            <div className="row expertise__info">
              {
                // loop to get Expercise title and value
                expertise.map((item, index) => {
                  return (
                    <div className="col-lg-6" key={index}>
                      <h4 className="title">
                        <span>{`0${index + 1}`}</span>
                        {item.title}
                      </h4>
                      <p className="value">{item.value}</p>
                    </div>
                  );
                })
              }
            </div>
          </div>
          {/* col-md-half9 End */}
        </div>
        {/* row End */}
      </div>
    </section> // Expertise section End
  );
};

export default Expertises;
