import React from "react";
import ResumeCv from "../components/home/ResumeCv";
import "normalize.css";
import Seo from "../components/seo/Seo";

const Home = () => {
  return (
    <>
      <Seo title="Hassaan Tahir" />
      <ResumeCv />
    </>
  );
};
export default Home;
