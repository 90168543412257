import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

// fetch get from grapjql
const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const Seo = ({ title, description }) => {
  const data = useStaticQuery(query);
  const siteData = data.site.siteMetadata; // Destructure to get object data
  const defaultTitle = siteData.title; // SiteMetaData Title
  const defaultDescription = description || siteData.description;
  const defaultAuthor = siteData.author; // SiteMetaData Author
  return (
    <>
      <Helmet
        title={title || defaultTitle}
        meta={[
          {
            name: `description`,
            content: defaultDescription,
          },
          {
            name: `author`,
            content: defaultAuthor,
          },
        ]}
      />
    </>
  );
};

export default Seo;

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
};
Seo.defaultProps = {
  title: null,
  description: null,
  author: "@desmondResume",
};
