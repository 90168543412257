import React from "react";
import { useSelector } from "react-redux";
import ResumeCv from "../../constants/ResumeCv.json";
import Title from "../title/Title";

const Education = () => {
  const selectMode = useSelector(({ IsDarkMode }) => IsDarkMode);
  const education = ResumeCv.education; // get Education data from Json
  return (
    <section className={`education ${selectMode ? "" : "light__mode"}`}>
      {/* Education section start */}
      <div className="container-fluid">
        <div className="row">
          {/* row start */}
          <div className="col-md-half4">
            {/* col-md-half4 start */}
            <div className="education__title">
              <Title
                title="education"
                subtitle="Lazy isn't in my vocabulary."
              />
            </div>
          </div>
          {/* col-md-half4 End */}
          <div className="col-md-half9">
            {/* col-md-half9 start */}
            <div className="education__info">
              {
                // loop to get Education filed
                education.map((item, index) => {
                  return (
                    <div className="row" key={index}>
                      <div className="col-lg-6">
                        <div className="round__dot"></div>
                        <span className="workingYear">
                          {item.educationtimeframe}
                        </span>
                        <h4 className="company">{item.university}</h4>
                        <h5 className="position">{item.degree}</h5>
                        <h6 className="location">{item.location}</h6>
                      </div>
                      <div className="col-lg-6 description">
                        <p>{item.description}</p>
                      </div>
                    </div>
                  );
                })
              }
              <div className="underline"></div>
            </div>
          </div>
          {/* col-md-half9 End */}
        </div>
        {/* row End */}
      </div>
    </section> // Experience section End
  );
};

export default Education;
