import React from "react";

const Title = ({title, subtitle}) => {
    return (
        <>
            <h2>{title}</h2>
            <h3>{subtitle}</h3>
        </>
    )
}

export default Title;
