import React from "react";
import avater from "../../assets/images/header/dp.jpg";
import { FaLinkedinIn } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaSkype } from "react-icons/fa";
import ResumeCv from "../../constants/ResumeCv.json";
import { useSelector } from "react-redux";
import ContactInfo from "../ContactInfo/ContactInfo";
import Fade from "react-reveal/Fade";

const Basic = () => {
  const selectMode = useSelector(({ IsDarkMode }) => IsDarkMode);
  let contactInfo = ResumeCv.contactInfo; //  get Contact info from json file

  return (
    <>
      <section className={`basic ${selectMode ? "" : "light__mode"} `}>
        {/* Basic section Start  */}
        <div className="container-fluid">
          <div className="row">
            {/* row Start  */}
            <div className="col-md-half4 vh-md-100 image__center ">
              {/* col-md-half4 Start  */}
              <div className="image__container">
                <Fade bottom duration={2000} delay={500}>
                  <img src={avater} alt="avatar" id="basic__image" />
                </Fade>
              </div>
            </div>
            {/* col-md-half4 End  */}
            <div className="col-md-half9 vh-md-100">
              {/* col-md-half9 Start  */}
              <div className="portfolio__container">
                <div className="info__container">
                  {/* Info__container Start  */}
                  <Fade bottom duration={2000} delay={1000}>
                    <h1 className="info__title">{ResumeCv.basic.name1}</h1>
                  </Fade>
                  <Fade bottom duration={2000} delay={1500}>
                    <h2 className="info__subtitle">{ResumeCv.basic.name2}</h2>
                  </Fade>
                  <div className="profession__container">
                    <Fade bottom duration={2000} delay={2000}>
                      <h3 className="info__profession">
                        {ResumeCv.basic.title}
                      </h3>
                    </Fade>

                    <div className="social__links">
                      <Fade bottom duration={2000} delay={2200}>
                        <a
                          href={contactInfo.linkedIn}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaLinkedinIn className="icons" />
                        </a>
                      </Fade>
                      <Fade bottom duration={2000} delay={2300}>
                        <a
                          href={contactInfo.github}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaGithub className="icons" />
                        </a>
                      </Fade>
                      <Fade bottom duration={2000} delay={2400}>
                        <a
                          href={contactInfo.skype}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaSkype className="icons" />
                        </a>
                      </Fade>
                    </div>
                  </div>
                </div>
                {/* Info__container Start  */}
                <div className="contact__Info">
                  {/* contact__Info Start  */}
                  <Fade bottom duration={2000} delay={2500}>
                    <div className="underline"></div>
                  </Fade>
                  <div className="row">
                    <div className="col-lg-3 title__info">
                      <Fade bottom duration={2000} delay={2700}>
                        <ContactInfo
                          title="Location"
                          value={contactInfo.location}
                          capitalize
                        />
                      </Fade>
                    </div>
                    <div className="col-lg-3 title__info">
                      <Fade bottom duration={2000} delay={2800}>
                        <ContactInfo title="Phone" value={contactInfo.phone} />
                      </Fade>
                    </div>
                    <div className="col-lg-3 title__info">
                      <a
                        href={contactInfo.web}
                        style={{ textDecoration: "none" }}
                      >
                        <Fade bottom duration={2000} delay={2900}>
                          <ContactInfo
                            title="Web"
                            value={contactInfo.web}
                            color
                          />
                        </Fade>
                      </a>
                    </div>
                    <div className="col-lg-3 title__info">
                      <a
                        href={`mailto:${contactInfo.email}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Fade bottom duration={2000} delay={3000}>
                          <ContactInfo
                            title="Email"
                            value={contactInfo.email}
                            color
                          />
                        </Fade>
                      </a>
                    </div>
                  </div>
                </div>
                {/* contact__Info End  */}
              </div>
            </div>
            {/* col-md-half9 End  */}
          </div>
          {/* row End  */}
        </div>
      </section>
      {/* Basic section End  */}
    </>
  );
};

export default Basic;
