import React from "react";
import Title from "../title/Title";
import ResumeCv from "../../constants/ResumeCv.json";
import { useSelector } from "react-redux";
import Fade from "react-reveal/Fade";

const Skills = () => {
  const selectMode = useSelector(({ IsDarkMode }) => IsDarkMode);
  const skill = ResumeCv.skill; // get skill data from Json file

  return (
    <section className={`skill ${selectMode ? "" : "light__mode"}`}>
      {/* Skill section start */}
      <div className="container-fluid">
        <div className="row">
          {/* row start */}
          <div className="col-md-half4">
            {/* col-md-half4 start */}
            <div className="skill__title">
              <Title title="skills" subtitle="Progress bars, anyone?" />
            </div>
          </div>
          {/* col-md-half4 End */}
          <div className="col-md-half9">
            {/* col-md-half9 start */}
            <div className="row skill__info" id="skill__info">
              {
                // loop to get skill title and progress
                skill.map((item, index) => {
                  return (
                    <div className="col-lg-6" key={index}>
                      <div className="title__container">
                        {/* <span>
                          {item.progress}
                          <small>%</small>
                        </span> */}
                        <h4 className="title">{item.title}</h4>
                      </div>
                      <Fade distance="50%" duration={5000}>
                        <div className="progress">
                          <div
                            aria-label="progress-bar"
                            className="progress-bar"
                            style={{ width: `${item.progress}%` }}
                            role="progressbar"
                            aria-valuenow={item.progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </Fade>
                    </div>
                  );
                })
              }
            </div>
          </div>{" "}
          {/* col-md-half9 End */}
        </div>
        {/* row End */}
      </div>
    </section> // Skill section End
  );
};

export default Skills;
