export const lightMode = () => {
  return {
    type: "LIGHTMODE",
  };
};
export const darkMode = () => {
  return {
    type: "DARKMODE",
  };
};
