import React from "react";
import Basic from "../basic/Basic";
import "../../sass/main.scss";
import SwitchButton from "../switchButton/SwitchButton";
import Intro from "../intro/Intro";
import { useSelector } from "react-redux";
import Expertises from "../expertises/Expertises";
import Skills from "../skills/Skills";
import Experience from "../experience/Experience";
import Education from "../education/Education";
import Profile from "../profile/Profile";
import Awards from "../awards/Awards";
import Portfolio from "../portfolio/Portfolio";
import Clients from "../clients/Clients";
import Contact from "../contact/Contact";

const Home = () => {
  const selectMode = useSelector(({ IsDarkMode }) => IsDarkMode);
  return (
    <main style={{ backgroundColor: `${selectMode ? "#2B2C2C" : "#fafafa"} ` }}>
      <SwitchButton />
      <Basic />
      <Intro />
      <Expertises />
      <Skills />
      <Experience />
      <Education />
      {/* <Profile /> */}
      {/* <Awards /> */}
      <Portfolio />
      {/* <Clients /> */}
      <Contact />
    </main>
  );
};

export default Home;
