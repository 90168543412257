import React from "react";

const ContactInfo = ({ title, value, color, capitalize }) => {
  return (
    <div className="title__container">
      <div className="title__name">{title}</div>
      <div
        className={`title__value ${color && "orange__clr"}`}
        style={{ textTransform: `${capitalize && "capitalize"}` }}
      >
        {value}
      </div>
    </div>
  );
};

export default ContactInfo;
